import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <h5>
        Copyright &#169; 2024, Developed by ABFSSL || All Rights Reserved.
      </h5>
    </div>
  );
};

export default Footer;
