import { Routes, Route } from "react-router-dom";
import "./App.css";
import {
  Login,
  Invoice,
  AdminDashboard,
  Error,
  Loading,
} from "./pages/pagesIndex.jsx";
import Invoic from "./pages/invoice_form/Invoice.jsx";
function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/invoice-form" element={<Invoice />} />
        <Route exact path="/admin-dashboard" element={<AdminDashboard />}></Route>
        <Route path="*" element={<Error />}></Route>
        <Route path="/loading" element={<Loading />}></Route>
        <Route path="/working" element={<Invoic />}></Route>
      </Routes>
    </>
  );
}

export default App;
