import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { InvoiceAPI } from "../../services/dbApi";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import "./AdminDashboard.css";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Card, CardMedia, CardActions } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import InvoiceForm from "../invoice_form/InvoiceForm";

// {
//   userID;
// }
const AdminDashboard = ({
  setCurrentTab,
  currentTab,
  setSelectedInvoice,
  invoiceSuccess,
}) => {
  const navigate = useNavigate();

  const userdetails = useSelector((state) => state.userdetails);
  /**- Error Handling -**/
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  /**- Invoice States -**/
  const [invoiceList, setInvoiceList] = useState();

  // const [currentTab, setCurrentTab] = useState(0);  //ak
  const [businessCodeList, setBusinessCodeList] = useState(); //ak
  const [businessAllGst, setBusinessAllGst] = useState(); //ak
  const userID = userdetails?.userData?.userID; //ak

  const notifyType = () =>
    toast.info(<h4>If Document No and Type Same Cant Be Updated</h4>, {
      autoClose: 3000,
      pauseOnHover: true,
      draggable: true,
      position: "top-right",
    });

  const Invoices = async (userId) => {
    try {
      setLoading(true);
      setError(false);
      const res = await InvoiceAPI.Invoices(userId);
      setInvoiceList(res?.data?.result);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
      setLoading(false);

      // setLoading(false);
    } catch (error) {
      if (error.response) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage(
          "Internal Server Isssue,Apologies for the inconvenience caused!"
        );
      }
      setError(true);
      setLoading(false);
      // setTimeout(() => {
      //   setLoading(false);
      // }, 3000);
    }
  };

  useEffect(() => {
    // Invoices(userID[0]);
    Invoices(userdetails.userData.userID);
  }, [userdetails.userData.userID]); // [inside this by ak]
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ];
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  const TABLE_HEADS = [
    //"InvoiceID",
    "Lob",
    "Document No",
    "Document Date",
    "Document Type",
    "Document Total",
    "Invoice Status",
    "Processing Status",
    "GST Matching Status",

    // "Action",
  ];
  const TABLE_DATA = invoiceList;

  ChartJS.register(ArcElement, Tooltip, Legend);

  const datas = {
    labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const handleDraftClick = async (invoice_id) => {
    try {
      // setCurrentTab(1);
      const res = await InvoiceAPI.InvoicesDraft(invoice_id);
      //setInvoiceList(res?.data?.result);
      console.log("this is response ", res.data.result[0]);
      setSelectedInvoice(res.data.result[0]);
      setCurrentTab(1);
    } catch (error) {
      console.log("failed to pre-populate the draft fields", error);
    }
  };

  return (
    <>
      {invoiceSuccess.isInvoiceUploaded == "true" && (
        <Grid container justifyContent="center">
          <Card
            sx={{ width: 700, alignSelf: "center", alignItems: "center" }}
            variant="outlined"
          >
            <CardMedia>
              <Alert
                severity={
                  invoiceSuccess.isInvoiceUploaded ? "success" : "error"
                }
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {invoiceSuccess.isDraft == "false" &&
                  "Invoice Uploaded successfully,Thank You!."}
                {invoiceSuccess.isDraft == "true" &&
                  "Draft Uploaded successfully,Thank You!."}
              </Alert>
            </CardMedia>
          </Card>
        </Grid>
      )}
      {invoiceSuccess.isInvoiceUploaded == "false" && (
        <Grid container justifyContent="center">
          <Card
            sx={{ width: 700, alignSelf: "center", alignItems: "center" }}
            variant="outlined"
          >
            <CardMedia>
              <Alert
                severity={
                  invoiceSuccess.isDraft == "true" ? "success" : "error"
                }
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {invoiceSuccess.isDraft == "false" &&
                  "Invoice submission failed. Document No with same Invoice Type already exist!."}
              </Alert>
            </CardMedia>
          </Card>
        </Grid>
      )}

      <div className="dashboard-top">
        {/* <h4>Dashboard</h4> */}
        {/* <h3>Filters</h3> */}
      </div>
      <div className="dashboard-cards">
        {/* <h4>Charts-KPI</h4> */}
        {/* <Pie data={datas} className="pie-chart" /> */}
      </div>
      <div className="content-area-table">
        <div className="data-table-info">
          <h4 className="data-table-title">Latest Invoices</h4>
        </div>
        <div className="data-table-diagram">
          <table>
            <thead>
              <tr>
                {TABLE_HEADS?.map((th, index) => (
                  <th key={index}>{th}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* { ₹
                          {new Intl.NumberFormat("en-IN").format(
                            row.total_invoice_amount
                          )}}          */}
              {/* {console.log(TABLE_DATA)} */}
              {TABLE_DATA?.map((dataItem, index) => {
                return (
                  <tr key={dataItem.invoice_id}>
                    {/* <tr key={index}></tr> */}
                    {/* <td>{dataItem.invoice_id}</td> */}
                    <td>{dataItem.lob}</td>
                    <td>{dataItem.document_no}</td>
                    <td>{dataItem.invoice_date}</td>
                    <td>{dataItem.document_type}</td>
                    <td>
                      {new Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      }).format(dataItem.total_invoice_amount)}
                      {/* {dataItem.total_invoice_amount} */}
                    </td>
                    {/*    <td>{dataItem.invoice_status}</td>  */}
                    <td>
                      {dataItem.invoice_status === "Draft" ? (
                        <span
                          className="clickable"
                          onClick={() => handleDraftClick(dataItem.invoice_id)}
                        >
                          {dataItem.invoice_status}
                        </span>
                      ) : (
                        dataItem.invoice_status
                      )}
                    </td>
                    {/* <td>
                      <div className="dt-status">
                        <span
                          className={`dt-status-dot dot-${dataItem.status}`}></span>
                        <span className="dt-status-text">
                          {dataItem.total_invoice_amount}
                        </span>
                      </div>
                    </td> */}
                    {/* <td>${dataItem.amount}</td> */}
                    {/* <td className="dashboard-table-action-cell"> */}
                    {/* <AreaTableAction />  */}
                    {/* <Button>
                        <MoreHorizRoundedIcon />
                      </Button> */}
                    {/* </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
