import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InvoiceForm from "./InvoiceForm";
import { Footer } from "../../components/componentsIndex";
import headerlogo from "../../assets/images/abc-logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.css";
import Loading from "../error/Loading";
import axios from "axios";
import { AuthAPI } from "../../services/dbApi";
import { AdminDashboard } from "../pagesIndex";
import { useSelector, useDispatch } from "react-redux";
import { setuser } from "../../state/features/userSlice";
//import "./App.js";

// {
//   userdetails;
// }
const MainContainer = () => {
  axios.defaults.withCredentials = true;
  const notify = () =>
    toast.success("👋,Welcome Back!", {
      autoClose: 2000,
      pauseOnHover: true,
      draggable: true,
    });
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [invoiceSuccess, setInvoiceSuccess] = useState({
    isInvoiceUploaded: 'test',
    isDraft: 'test',
  });


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [userID, setUserID] = useState([]);

  const userdetails = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();

  const [selectedInvoice, setSelectedInvoice] = useState(null); //ak

  const MenuItems = [
    {
      content: "Dashboard",
      icon: <ArrowForwardRoundedIcon />,
    },
    {
      content: "Upload Invoice",
      icon: <ArrowForwardRoundedIcon />,
    },
    // {
    //   content: "Invoice1",
    //   icon: <ArrowForwardRoundedIcon />,
    // },
  ];
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const res = await AuthAPI.isAuth();
        if (res.data.valid) {
          // console.log(res.data);
          // setUserID([res?.data?.userID, res?.data?.username]);
          dispatch(
            setuser({
              userID: res?.data?.userID,
              username: res?.data?.username,
              business_code: userdetails.userData.company_title,
            })
          );
          setTimeout(() => {
            setLoading(false);
            // notify();
          }, 3000);
        } else {
          setTimeout(() => {
            setLoading(false);
            return navigate("/", { isnotify: 1 });
          }, 3000);
        }
      } catch (error) {
        setError(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
        return navigate("/");
      }
    })();
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1024) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  // const ContentTab = [
  //   {
  //     title: "Dashboard",
  //     component: <AdminDashboard userID={userdetails.userData.userID} />,
  //   },
  //   {
  //     title: "Invoice",
  //     component: <InvoiceForm userID={userID} />,
  //   },
  // ];

  const ContentTab = [
    {
      title: "Dashboard",
      component: (
        <AdminDashboard
          setSelectedInvoice={setSelectedInvoice}
          invoiceSuccess={invoiceSuccess}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
          userID={userdetails.userData.userID}
        />
      ),
      //component: <AdminDashboard setCurrentTab={setCurrentTab} currentTab={currentTab} setSelectedInvoice={setSelectedInvoice}
      //userID={userdetails.userData.userID} />,
    },
    {
      title: "Invoice",
      component: (
        <InvoiceForm
          userID={userdetails.userData.userID}
          selectedInvoice={selectedInvoice}
          setCurrentTab={setCurrentTab}
          setInvoiceSuccess={setInvoiceSuccess}
        />
      ),
      //component: <InvoiceForm userID={selectedInvoice} />,
    },
  ];

  const handleDelete = () => {
    AuthAPI.logout()
      .then((res) => {
        navigate("/");
      })
      .catch((err) => console.log(err));
  };
  if (error) {
    return <h4>Network Error</h4>;
  }
  return loading ? (
    <Loading />
  ) : (
    <>
      <ToastContainer />
      <div className="header-container">
        <div className="header-main">
          <div className="header-logo">
            <img src={headerlogo} alt="Aditya Birla Capital" className="logo" />
          </div>
          <div className="header-right">
            <div className="btn">
              {/* <button> */}
              <PersonIcon />
              {/* </button> */}
              {/* <h5>{userID[1]}</h5> */}
              <h5>{userdetails?.userData?.username}</h5>
              {/* <h5>{userdetails?.userData?.userID}</h5> */}
            </div>
            <div className="btn">
              {/* <Button onClick={handleDelete}> */}
              <LogoutIcon onClick={handleDelete} />
              {/* </Button> */}
              <h5>Logout</h5>
            </div>
            <div
              id="btn-toggle-menu"
              onClick={() => {
                setActiveMenu(!activeMenu);
              }}
            >
              <MenuIcon />
            </div>
          </div>
        </div>
      </div>
      <main>
        {activeMenu ? (
          <aside>
            <button
              id="close-btn"
              onClick={() => {
                setActiveMenu(!activeMenu);
              }}
            >
              <CloseRoundedIcon />
            </button>
            <div className="dashboard-sidebar">
              {/* {userdetails} */}
              {MenuItems.map((item, index) => (
                <div
                  key={index} //ak
                  className={currentTab === index ? "btn active" : "btn"}
                  onClick={() => {
                    setCurrentTab(index);
                    setSelectedInvoice(null);
                  }}
                >
                  <span>{item.content}</span>
                  {item.icon}
                </div>
              ))}
            </div>
          </aside>
        ) : (
          ""
        )}
        <section className="stepper-invoice-container">
          {ContentTab[currentTab].component}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default MainContainer;
