import React from "react";
import icon from "../../assets/images/404.gif";

const Error = () => {
  const mystyle = {
    // display: "flex",
    textAlign: "center",
    padding: "10px",
    fontFamily: "Arial",
    backgroundColor: "white",
  };

  return (
    <>
      <div className="" style={mystyle}>
        <h2>404!</h2>
        <h2>LOOKS LIKE YOU ARE LOST!</h2>
        <h3 className="btn">
          <a href="/">Go Back To Home!</a>
        </h3>
        <img
          src={icon}
          alt="LOOKS LIKE YOU ARE LOST IN THE ABCL SPACE.!
"
          style={{ width: 350, height: 350 }}
        />
      </div>
    </>
  );
};

export default Error;
