import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Loading from "../../error/Loading";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthAPI } from "../../../services/dbApi";
import { useSelector, useDispatch } from "react-redux";
import { setuser } from "../../../state/features/userSlice";
import { Refresh } from "@mui/icons-material";

const LoginComponent = ({ fnc }) => {
  const userdetails = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();
  const [userdetail, setUserDetails] = useState({
    vendor_code: "",
    password: "",
    otp: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const notify = () =>
    toast.error("🙏Please Login!", {
      autoClose: 2000,
      pauseOnHover: true,
      draggable: true,
      position: "bottom-right",
    });

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput("");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async () => {
    setErrorMessage("");
    setError(false);
    if (captcha !== captchaInput) {
      setErrorMessage("Invalid CAPTCHA. Please try again.");
      refreshCaptcha();
      setError(true);
      return;
    }

    const userData = {
      username: userdetail.vendor_code,
      password: userdetail.password,
    };

    try {
      setLoading(true);
      setError(false);

      if (!otpSent) {
        // Send OTP
        const response = await AuthAPI.loginSendOtp({
          email: userData.username,
        });
        setOtpSent(true);
        setLoading(false);
        toast.success(response.data.message);
      } else if (!otpVerified) {
        // Verify OTP
        const response = await AuthAPI.loginVerifyOtp({
          email: userdetail.vendor_code,
          otp: userdetail.otp,
          password: userdetail.password,
        });

        if (response.data.token) {
          localStorage.setItem("token", response.data.token);
          setOtpVerified(true);
          setLoading(false);
          toast.success("OTP verified successfully!");
          navigate("/invoice-form");
        }
      } else {
        // Login user
        // const response = await AuthAPI.loginUser(userData);
        // navigate("/invoice-form");
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage(error?.response?.data?.message);
      } else {
        setErrorMessage(
          "Internal Server Issue, Apologies for the inconvenience caused!"
        );
      }
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    AuthAPI.isAuth()
      .then((res) => {
        if (res.data.valid) {
          dispatch(
            setuser({
              4: res.data.session,
              //username: res.data.session.username, 
              //business_code: res.data.session.business_code, 
              username: userdetails.userData.company_title,
              business_code: userdetails.userData.business_code,
            })
          );
          return navigate("/invoice-form", { state: res.data.session });
        } else {
          console.log("User not logged in");
          return navigate("/");
        }
      })
      .catch((err) => {
        return navigate("/");
      });
  }, [navigate]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <div className="box-login register-a">
          <ToastContainer />
          <div className="top-header">
            <h3>Login</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group">
              <div className="review-input-field">
                <label>User ID</label>
                <input
                  type="Email"
                  placeholder="User ID"
                  className="input-box"
                  id="logEmail"
                  {...register("userID", {
                    required: "Please Enter UserID",
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/,
                      message: "Invalid email address",
                    },
                  })}
                  autoComplete="on"
                  onChangeCapture={(e) =>
                    setUserDetails({
                      ...userdetail,
                      vendor_code: e.target.value,
                    })
                  }
                  onCopy={(e) => e.preventDefault()}
                  onCut={(e) => e.preventDefault()}
                  onPaste={(e) => e.preventDefault()}
                />
                <div className="remember" style={{ color: "red" }}>
                  {errors?.userID && <span>{errors?.userID?.message}</span>}
                </div>
              </div>
              <div className="review-input-field">
                <label>Password</label>
                <div className="eye-area">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="input-box"
                    id="logPassword"
                    {...register("Password", {
                      required: "Please Enter Password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_.@!#$-])(?!.* )(?!.*[`~<>}{\]'}\\"/,=]).{8,}$/,
                        message: "Invalid Password",
                      },
                    })}
                    autoComplete="off"
                    onChangeCapture={(e) =>
                      setUserDetails({
                        ...userdetail,
                        password: e.target.value,
                      })
                    }
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                  <span className="eye-icon" onClick={handleClickShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </span>
                </div>
                <div className="remember" style={{ color: "red" }}>
                  {errors?.Password && <span>{errors?.Password?.message}</span>}
                </div>
              </div>
              {otpSent && !otpVerified && (
                <div className="review-input-field">
                  <label>OTP</label>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    className="input-box"
                    id="logOtp"
                    onChange={(e) =>
                      setUserDetails({
                        ...userdetail,
                        otp: e.target.value,
                      })
                    }
                    maxLength={6}
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                </div>
              )}
              <div className="review-input-field">
                <div className="captcha-container">
                  <span
                    style={{
                      backgroundImage: `url(../../assets/images/captcha1.png')`,
                    }}
                  >
                    {captcha}
                  </span>
                  <Refresh onClick={refreshCaptcha} className="refresh-icon" />
                  <input
                    type="text"
                    value={captchaInput}
                    maxLength={6}
                    onChange={(e) => setCaptchaInput(e.target.value)}
                    placeholder="Enter CAPTCHA"
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    className="captcha-input"
                  />
                </div>
              </div>
              <div className="remember" style={{ color: "red" }}>
                {errorMessage}
              </div>
              <div className="input-field">
                <div className="App"></div>
                <div className="App"></div>
                <input
                  type="submit"
                  className="input-submit"
                  value={
                    otpSent
                      ? otpVerified
                        ? "Logged In"
                        : "Verify OTP & Login"
                      : "Send OTP"
                  }
                  required
                />
              </div>
              <div className="forgot">
                <Link onClick={() => fnc(2)}>Forgot password?</Link>
              </div>
            </div>
          </form>
        </div>
      )}
      <div className="notice-message">
        <span>
          {/* ATTENTION USERS: Starting 07th October, MFA using an OTP via email
          will be enabled for all users. Please ensure that your registered
          email address is up-to-date. */}
        </span>
      </div>
    </>
  );
};

export default LoginComponent;

const generateCaptcha = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};
