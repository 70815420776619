import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const RegisterComponent = ({ fnc }) => {
  const [userdetails, setUserDetails] = useState({
    vendor_code: "",
    password: "",
  });
  const [auth, setAuth] = useState(false);
  const [message, setMessage] = useState("");
  const [UserIDError, setUserIDError] = useState("");
  const [responseData, setResponseData] = useState("");
  axios.defaults.withCredentials = true;
  const navigate = useNavigate();
  const handleRegister = (event) => {
    // event.preventDefault();
    const userData = {
      username: userdetails.username,
      password: userdetails.password,
    };
    axios
      .post("http://localhost:3001/api/auth/register/", userData)
      .then((res) => {
        console.log(res);
        alert("Registered Successfully!");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="box-login register-a">
        <div class="top-header">
          <h3>Sign Up, Now!</h3>
        </div>
        <div className="input-group">
          <div class="review-input-field">
            <label for="regUsername">Username</label>
            <input
              type="text"
              class="input-box"
              id="regUsername"
              onChange={(e) =>
                setUserDetails({ ...userdetails, username: e.target.value })
              }
              required
            />
          </div>
          {/* <div className="review-input-field">
                         <label for="regEmail">Email address</label>
                         <input type="text" class="input-box" id="regEmail" required />
                                   </div> */}
          <div class="review-input-field">
            <label for="regPassword">Password</label>
            <input
              type="password"
              class="input-box"
              id="regPassword"
              onChange={(e) =>
                setUserDetails({ ...userdetails, password: e.target.value })
              }
              required
            />
            <div class="eye-area">
              <div class="eye-box" onclick="myRegPassword()">
                {" "}
                <i class="fa-regular fa-eye" id="eye-2"></i>
                <i class="fa-regular fa-eye-slash" id="eye-slash-2"></i>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div class="remember">
            <input type="checkbox" id="formCheck2" class="check" />
            <label for="formCheck2">Remember Me</label>
          </div>
          <div class="input-field">
            <input
              type="submit"
              class="input-submit"
              value="Sign Up"
              onClick={() => handleRegister()}
              required
            />
          </div>
        </div>
        <div className="switch">
          <div className="switch-child">
            <a
              onClick={() => {
                fnc(0);
              }}
            >
              Already a member? Sign In
            </a>
          </div>
          {/* <p>Forgot Password</p> */}
          {/* <p>Don't have an Account?<a href="#">Sign Up</a></p> */}
        </div>
      </div>
    </>
  );
};

export default RegisterComponent;
