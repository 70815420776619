import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AuthAPI } from "../../../services/dbApi";
import { Loading } from "../../pagesIndex";

const ForgotPassword = ({ fnc }) => {
  const [userdetails, setUserDetails] = useState({
    vendor_code: "",
    password: "",
    otp: "",
  });
  const [step, setStep] = useState(0); // 0: Enter Email, 1: Enter OTP, 2: Reset Password
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const sendOtp = async () => {
    try {
      setLoading(true);
      setError(false);
      await AuthAPI.sendOtp({ email: userdetails.username });
      setOtpSent(true);
      setStep(1);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setErrorMessage(
        error?.response?.data?.message || "Failed to send OTP. Try again."
      );
    }
  };

  const verifyOtp = async () => {
    try {
      setLoading(true);
      setError(false);
      await AuthAPI.verifyOtp({
        email: userdetails.username,
        otp: userdetails.otp,
      });
      setOtpVerified(true);
      setStep(2);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
      setErrorMessage(
        error?.response?.data?.message || "Invalid OTP. Try again."
      );
    }
  };

  const changePassword = async () => {
    const userData = {
      username: userdetails.username,
      password: userdetails.password,
    };
    try {
      setLoading(true);
      setError(false);
      await AuthAPI.resetPassword(userData);
      setLoading(false);
      setStep(3); // Password reset successfully
    } catch (error) {
      setError(true);
      setLoading(false);
      setErrorMessage(
        error?.response?.data?.message ||
          "Internal Server Issue. Apologies for the inconvenience caused!"
      );
    }
  };

  return (
    <>
      {step === 3 ? (
        <div className="box-login">
          <Alert severity="success">Password Successfully Changed!</Alert>
          <div className="switch">
            <div className="switch-child">
              <Link
                onClick={() => {
                  fnc(0);
                }}
              >
                Sign In
              </Link>
              <br />
            </div>
          </div>
        </div>
      ) : (
        <div className="box-login register-a">
          <div className="top-header">
            <h3>Forgot Password!</h3>
          </div>
          <form
            onSubmit={handleSubmit(
              step === 0 ? sendOtp : step === 1 ? verifyOtp : changePassword
            )}
          >
            <div className="input-group">
              {step === 0 && (
                <div className="review-input-field">
                  <input
                    type="Email"
                    placeholder="User ID"
                    className="input-box"
                    id="logEmail"
                    {...register("userID", {
                      required: "Please Enter UserID",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/,
                        message: "Invalid email address",
                      },
                    })}
                    autoComplete="on"
                    onChangeCapture={(e) =>
                      setUserDetails({
                        ...userdetails,
                        username: e.target.value,
                      })
                    }
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                  />
                  {errors?.userID && (
                    <div className="remember" style={{ color: "red" }}>
                      <span>{errors?.userID?.message}</span>
                    </div>
                  )}
                </div>
              )}

              {step === 1 && (
                <>
                  <div className="review-input-field">
                    <input
                      type="text"
                      placeholder="Enter OTP"
                      className="input-box"
                      id="otp"
                      maxLength={6}
                      {...register("otp", {
                        required: "Please Enter OTP",
                        pattern: {
                          value: /^[0-9]{6}$/,
                          message: "Invalid OTP",
                        },
                      })}
                      autoComplete="off"
                      onChangeCapture={(e) =>
                        setUserDetails({
                          ...userdetails,
                          otp: e.target.value,
                        })
                      }
                      onCopy={(e) => e.preventDefault()}
                      onCut={(e) => e.preventDefault()}
                      onPaste={(e) => e.preventDefault()}
                    />
                    {errors?.otp && (
                      <div className="remember" style={{ color: "red" }}>
                        <span>{errors?.otp?.message}</span>
                      </div>
                    )}
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <div className="review-input-field">
                    <label>Enter New Password</label>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="input-box"
                      id="regPassword"
                      placeholder="Password"
                      {...register("Password", {
                        required: "Please Enter Password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                        pattern: {
                          value:
                            /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_.@!#$-])(?!.* )(?!.*[`~<>}{\]'}\\"/,=]).{8,}$/,
                          message: "Invalid Format",
                        },
                      })}
                      autoComplete="on"
                      onChangeCapture={(e) =>
                        setUserDetails({
                          ...userdetails,
                          password: e.target.value,
                        })
                      }
                      // onCopy={(e) => e.preventDefault()}
                      // onCut={(e) => e.preventDefault()}
                      // onPaste={(e) => e.preventDefault()}
                    />
                    <div className="eye-area">
                      <i className="eye-box" onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </i>
                    </div>
                    {errors?.Password && (
                      <div className="remember" style={{ color: "red" }}>
                        <span>{errors?.Password?.message}</span>
                      </div>
                    )}
                  </div>
                </>
              )}

              {error && (
                <div className="remember" style={{ color: "red" }}>
                  {errorMessage}
                </div>
              )}

              <div className="input-field">
                <input
                  type="submit"
                  className="input-submit"
                  value={
                    step === 0
                      ? "Send OTP"
                      : step === 1
                      ? "Verify OTP"
                      : "Reset Password"
                  }
                />
              </div>

              <div className="switch">
                <div className="switch-child">
                  <Link
                    onClick={() => {
                      fnc(0);
                    }}
                  >
                    Already a member? Sign In
                  </Link>
                  <br />
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
