import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  userData: {
    userID: "userID",
    username: "User",
    business_code: "Business Code",
  },
};
export const userSlice = createSlice({
  name: "userdetails",
  initialState,
  reducers: {
    setuser: {
      reducer: (state, action) => {
        state.userData = action.payload;
      },
      //   prepare: (userdetails) => {
      //     return { payload: userdetails };
      //   },
    },
  },
});

export const { setuser } = userSlice.actions;
export default userSlice.reducer;
