import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./Invoice.css";
import { useSelector, useDispatch } from "react-redux";
import { setuser } from "../../state/features/userSlice";

const Invoice = () => {
  const { register, errors } = useForm();
  const count = useSelector((state) => state.counter.value);
  const userdetails = useSelector((state) => state.userdetails);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setuser({
        username: userdetails.userData.username,
        company_title: userdetails.userData.company_title,
        business_code: "ABFS",
      })
    );
  }, [dispatch]);
  return (
    <>
      <div>
        {userdetails?.userData?.username}
        <hr />
        {userdetails?.userData?.company_title}
        <hr />
        {userdetails?.userData?.business_code}
        {/* {() => dispatch(setuser("Sndeep"))} */}
        {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(setuser(["sandep"]))}>
          Increment
        </button> */}
        {/* <button
          aria-label="Increment value"
          onClick={() => dispatch(increment())}>
          Increment
        </button>
        <span>count</span>
        <button
          aria-label="Decrement value"
          onClick={() => dispatch(decrement())}>
          Decrement
        </button> */}
      </div>
      <div className="invoice-container">
        <div className="invoice-from-container">
          <div className="invoice-from">
            <div className="invoice-from-field">
              <label>
                <h4>SOLD BY:</h4>
              </label>
              <textarea rows={5}></textarea>
            </div>
            <div className="invoice-from-field">
              <label>Pan No:</label>
              <input></input>
            </div>
            <div className="invoice-from-field">
              <label>GST No:</label>
              <input></input>
            </div>
          </div>
          <div className="invoice-logo">
            <p>LOGO</p>
          </div>
        </div>
        <div className="invoice-business-address">
          <div className="invoice-billing">
            <div className="invoice-billing-field">
              <label>
                <h4>BILLING ADDRESS:</h4>
              </label>
              <textarea rows={5}>
                G Corp Tech park,Kasarvadavali,Ghodbunder
                Road,Thane(W),Thane-400601,Maharashtra
              </textarea>
            </div>
            <div className="invoice-billing-field">
              <label htmlFor="">Billing State Name:</label>
              <input type="text" />
            </div>
            <div className="invoice-billing-field">
              <label>Billing State/UT Code</label>
              <input type="text" />
            </div>
          </div>
          <div className="invoice-shipping">
            <div className="invoice-shipping-field">
              <label>
                <h4>SHIPPING ADDRESS:</h4>
              </label>
              <textarea rows={5}></textarea>
            </div>
            <div className="invoice-shipping-field">
              <label>Shipping State:</label>
              <input type="text" />
            </div>
            <div className="invoice-shipping-field">
              <label>Shipping State/UT Code:</label>
              <input type="text" />
            </div>
          </div>
          <div className="invoice-po-meta">
            <div className="invoice-po-meta-field">
              <label className="required">Document Type :</label>
              <input></input>
            </div>
            <div className="invoice-po-meta-field">
              <label className="required">Document No :</label>
              <input></input>
            </div>
            <div className="invoice-po-meta-field">
              <label className="required">INV/DBN/CRN Date:</label>
              <input></input>
            </div>
            <div className="invoice-po-meta-field">
              <label>P.O.</label>
              <input></input>
            </div>
            <div className="invoice-po-meta-field">
              <label>P.O. DATE</label>
              <input type="text"></input>
            </div>
            <div className="invoice-po-meta-field">
              <label>Remaining PO Amount(in ₹) </label>
              <input value="90000"></input>
            </div>
          </div>
        </div>
        <div className="invoice-einvoice-invoice-values">
          <div className="invoice-values">
            <div className="invoice-values-field">
              <label>Whether GST payable under RCM </label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Basic invoice value (in ₹)</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Discount if any</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Transport Charge/Insurance Charge/Others </label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label> Assessable Value</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Customs Duty/CVD/SWC/Others</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label> Taxable Value</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>CGST Total</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>UTGST Total</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>IGST Total</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>SGST Total</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Cess</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Round off value (+/-)</label>
              <input></input>
            </div>
            <div className="invoice-values-field">
              <label>Total Invoice amount(in ₹)</label>
              <input></input>
            </div>
            <div className="invoice-values-field">Total Tax amount(in ₹) 0</div>
            <div className="invoice-values-field">
              Total Invoice amount In Words:
            </div>
          </div>
          <div className="einvoice-values">
            <div className="einvoice-values-field">
              <label>HSN Code [of main item] HSN Code</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Upload invoice copy</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Upload support documents</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label> E-invoice Available</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label> Whether Imports(yes/no)</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Bill Of Entry No</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Import Date</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label> E-way Bill no.(if applicable)</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>QR code with IRN no.</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Invoice upload status in GSTN portal- GSTR-1</label>
              <input></input>
            </div>
            <div className="einvoice-values-field">
              <label>Contact Person Email ID</label>
              <input></input>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
