import React, { useState, useEffect } from "react";
import RegisterComponent from "./components/RegisterComponent";
import LoginComponent from "./components/LoginComponent";
import ForgotpasswordComponent from "./components/ForgotPassword";
import { Footer } from "../../components/componentsIndex";
import "../../components/Header.css";
import "./login.css";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";

import headerlogo from "../../assets/images/abc-logo.png";
import logo from "../../assets/images/vendor-portal.jpg";
const Login = () => {
  /*-- Header Code  --*/
  const [activeMenu, setActiveMenu] = useState(true);
  const [screenSize, setScreenSize] = useState(null);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize < 1024) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);
  /*-- Header Responsive Code End  --*/

  /*-- Login Components  --*/
  const [currentForm, setcurrentForm] = useState(0);
  let comfun = (currentForm) => {
    if (currentForm === 0) {
      return <LoginComponent fnc={setcurrentForm} />;
    } else if (currentForm === 1) {
      return <RegisterComponent fnc={setcurrentForm} />;
    } else if (currentForm === 2) {
      return <ForgotpasswordComponent fnc={setcurrentForm} />;
    }
  };

  return (
    <>
      {/*-- Header Code  --*/}
      <div className="header-container">
        <div className="header-main">
          <div className="header-logo">
            <img src={headerlogo} alt="Aditya Birla Capital" className="logo" />
          </div>
          <div className="header-right">
            <div className="user-profile">
              <h5>Home</h5>
              <HomeIcon />
            </div>
            <div className="user-profile">
              <h5>Login</h5>
              <PersonIcon />
            </div>
            <div
              id="btn-toggle-menu"
              onClick={() => {
                setActiveMenu(!activeMenu);
              }}
            >
              <MenuIcon />
            </div>
          </div>
        </div>
      </div>
      {/*-- Header End  --*/}
      {/*-- Login - Register - Forgot Password --*/}
      <div className="form-container">
        <div className="form-wrapper">
          <img src={logo} alt="Partner-Portal" className="logo-class" />
          {comfun(currentForm)}
        </div>
      </div>
      {/*-- Login - Register - Forgot Password-- END*/}
      {/*-- Footer Start  --*/}
      <Footer />
      {/*-- Footer End  --*/}
    </>
  );
};

export default Login;
