import { Stack } from "@mui/material";
import axios from "axios";
const authInstance = axios.create({
  baseURL: "/",
  withCredentials: true,
});

export const AuthAPI = {
  loginUser: (user) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/login",
      data: user,
    });
  },
  isAuth: () => {
    return authInstance.request({
      method: "get",
      url: "api/login",
    });
  },
  resetPassword: (userdata) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/reset-password",
      data: userdata,
    });
  },
  logout: () => {
    return authInstance.request({
      method: "get",
      url: "/api/logout",
    });
  },

  sendOtp: (data) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/sendOtp",
      data: data,
    });
  },

  verifyOtp: (data) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/verifyOtp",
      data: data,
    });
  },

  loginSendOtp: (data) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/loginSendOtp",
      data: data,
    });
  },

  loginVerifyOtp: (data) => {
    return authInstance.request({
      method: "POST",
      url: "api/v1/auth/loginVerifyOtp",
      data: data,
    });
  },
};

const InvoiceInstance = axios.create({
  baseURL: "/",
  withCredentials: true,
});

export const InvoiceAPI = {
  business: (companycode) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/business",
      data: { company_code: companycode },
    });
  },
  businessAll: (partner_code) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/allbusiness",
      data: { partner_code: partner_code },
    });
  },
  businessGST: (gst_no) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/businessgst",
      data: { gst_no: gst_no },
    });
  },
  getSupplier: (partner_code, business_code) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/getsupplier",
      data: { partner_code: partner_code, business_code: business_code },
    });
  },
  getPODetails: (partner_code, business_code) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/getpodetails",
      data: { partner_code: partner_code, business_code: business_code },
    });
  },
  uploadInvoice: (formData) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/upload/invoice_upload",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
  Invoices: (partner_code, business_code) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/supplier/invoices",
      data: { partner_code: partner_code, business_code: business_code },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },

  InvoicesDraft: (invoice_id) => {
    return InvoiceInstance.request({
      method: "get",
      url: `/api/supplier/invoicesDraft?invoice_id=${invoice_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateInvoiceDraft: (formData) => {
    return InvoiceInstance.request({
      method: "post",
      url: "/api/upload/invoice_upload/draft",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
  },
};
